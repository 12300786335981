<template>
  <div class="login-form d-flex flex-column align-center justify-center fill-height">

    <div class="right text-center">
      <div class="title primary--text mb-8">{{$t("app.login.resetPassword.forgottenPasswordTitle")}}</div>
      <v-form ref="formResetPassword" class="d-flex flex-column align-center">
        <v-text-field v-model="email" :disabled="disabled" :label="$t('home.login.mailAddress')" :rules="ValidationRules.email" solo  dense rounded></v-text-field>

        <v-btn color="primary" :disabled="disabled" :loading="loading" type="submit" @click.prevent="resetPassword" rounded>{{ $t("app.login.resetPassword.resetPassword") }}</v-btn>

        <v-btn text x-small @click="$router.push({name: 'Login'})" class="primary--text subtitle mt-8">{{ $t('app.login.resetPassword.backToLogin') }}</v-btn>
      </v-form>
    </div>

  </div>
</template>

<script>


import LoginService from "@/Services/LoginService"
import MixinValidation from "@/Mixins/ValidationRules"


export default {
  name: "ResetPasswordForm",
  mixins: [MixinValidation],
  data() {
    return {
      email: null,
      loading: false,
      disabled: false
    }
  },
  methods: {
    resetPassword: async function () {
      if (this.$refs.formResetPassword.validate()) {
        this.loading = true
        let lang = navigator.language.split("-")[0] || 'en'
        let response = await LoginService.resetPassword(this.email, lang)

        if (response.ret == 1) {
          if (response.error) {
            this.$nError(this.$t("app.login.resetPassword." + response.error))
            this.loading = false
          } else {
            await this.sleep(2000)
            this.loading = false
            this.$nSuccess(this.$t("app.login.resetPassword.mailSent"), "", 5000)
            this.disabled = true
          }
        } else {
          if (response.error) {
            this.$nError(this.$t("app.login.resetPassword." + response.error))
          }
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>
  .right {
    width: 66%;
  }
</style>

